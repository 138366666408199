<script setup lang="ts">
import type { Collections } from '@nuxt/content'

const props = defineProps<{
  path: string
}>()

const route = useRoute()
const { locale } = useI18n()

const { data } = await useAsyncData(
  'content:query-navigation:' + route.path,
  async () => {
    const collection = getContentCollection('all', locale.value).name as keyof Collections
    const content = await queryCollectionNavigation(collection)
    return content
  },
  { watch: [locale] },
)
</script>

<template>
  <DebugDumpArray :items="data" />
</template>
